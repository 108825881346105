import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dataChanged } from '../../../components/data_save/data_save_slice'
import { Notification } from "@haravan/react-components";
import { TransactionRepository, PermissionRepository } from '../../../repositories'
import { isEqual, setDefaultValue, toDateTimeString } from '../../../global/utils/functions'
import { Currency, Email, Link, Status } from '../../../components'
import { useParams } from 'react-router-dom'
import * as HrvComponents from '@haravan/react-components';
import { STATUS_APP_NAME } from '../../../components/status/status';
import { debounce } from 'lodash'
import { selectUser } from "../../auth/services/auth_slice";

import './info.css'
import { CardAddress, CardTransaction, CardTransactionInfo } from '../../../components/card_detail/card_detail'
import { PageLayout } from '../../../components/page/page_layout'
import { Card } from 'reactstrap'
import TagsInput from '../../../components/input/tags_input'
import { DetailTransaction } from '../../../components/link/link';
import { CurrencyTransaction, FormatCategory } from '../../../components/format/format';

export const TransactionInfo = props => {
  // const { partnerId, onChangeData } = props
  const { transactionId } = useParams()

  const [transactionOrigin, setTransactionOrigin] = useState(null)
  const [transaction, setTransaction] = useState(null);
  const [note, setNote] = useState("");
  const [invoiceno, setInvoiceno] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [listUser, setListUser] = useState(null);
  const [showUpdateListUser, setShowUpdateListUser] = useState(false);
  const [transactionUser, setTransactionUser] = useState(null);
  const [isLoadingData, setLoadingData] = useState(true)
  const ref_element = useRef(null);
  const [canUpdateUser, setCanUpdateUser] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const [isFeaturesExpand, setIsFeaturesExpand] = useState(false);


  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const features_expand = ["Users Expand", "Fanpages Expand", "Stores Expand"];

  useEffect(() => {
    user &&
    PermissionRepository.GetFunctions(user.profile?.sub).then(rs => {
        setCanUpdateUser(rs?.data?.findIndex(r => ['inside.admin', 'inside.transaction_user'].includes(r.code)) > -1)
      })
  }, [user])
  useEffect(() => {
    !transactionOrigin &&
      TransactionRepository.GetDetail(transactionId).then(rs => {
        setTransactionOrigin(setDefaultValue(rs.data))
        setTransaction(setDefaultValue(rs.data))
        
        setLoadingData(false)
      })
    return () => {
      dispatch(dataChanged({ hasChanged: false, data: null }))
    }
  }, [])

  useEffect(() => {
    canUpdateUser && getListUser();
    transaction && setNote(transaction?.note);
    transaction && setInvoiceno(transaction?.invoiceNo);
    if (transaction?.type == "Theme" || transaction?.type == "Package" || transaction?.type == "In other service"
      || transaction?.type == "Customize" || transaction?.type == "Topup") {
      setIsPackage(true);
    } else setIsPackage(false);
  }, [transaction])

  useEffect(() => {
    canUpdateUser && searchUser != "" && getListUser();
  }, [searchUser])

  const UpdateInvoiceno = () => {
    if (note != null) {
      const model = {
        InvoiceNo: invoiceno
      }
      TransactionRepository.UpdateInvoiceno(transaction?.id, model).then(rs => {
        if (!rs.has_error)
          Notification.success({ message: 'Cập nhật InvoiceNo thành công' });

      })
    }
  } 
  const UpdateNote = () => {
    if (note != null) {
      const model = {
        Note: note
      }
      TransactionRepository.UpdateNote(transaction?.id, model).then(rs => {
        if (!rs.has_error)
          Notification.success({ message: 'Cập nhật ghi chú thành công' });

      })
    }
  }
  const UpdateTransactionUser = () => {
    if (!transactionUser) {
      Notification.error({ message: 'Vui lòng chọn nhân viên' });
      return;
    }
    if (transaction?.transactionUserId != null) {
      const model = {
        TransactionUserId: transaction?.transactionUserId
      }
      TransactionRepository.UpdateTransactionUser(transaction?.id, model).then(rs => {
        if (rs.data) {
          Notification.success({ message: 'Cập nhật nhân viên thành công' });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          Notification.error({ message: 'Vui lòng cập nhật nhân viên mới' });
        }
      })
    }
  }

  const getListUser = async () => {
    const response = await PermissionRepository.GetUsersBase(1, 5, searchUser);
    setListUser(response?.data);
  }

  const changeData = (fieldName, oldData, newData) => {
    let partnerClone = { ...transaction }
    const isChanged = oldData !== newData

    if (isChanged) partnerClone = { ...transaction, [fieldName]: newData }
    else delete partnerClone[fieldName]

    dispatch(
      dataChanged({
        hasChanged: !isEqual(transactionOrigin, partnerClone),
        data: partnerClone,
      })
    )

    setTransaction(partnerClone)
  }

  const { app, theme, shopPlan } = transaction ?? {};
  const serviceName = app ? app.name : theme ? theme.name : shopPlan ? shopPlan.name : '';
  const servicePrice = transaction?.type == 'Shop plan' ? shopPlan?.cost ?? 0 : transaction?.amount ?? 0;
  const serviceUnit = transaction?.type == 'Shop plan' || transaction?.type == 'Shop feature' ? ' / 1 tháng':'';
  
  const messageSuccess = 'Giao dịch đã thanh toán vào lúc ' + toDateTimeString(transaction?.completeDate);
  const messageCancel = transaction?.listHistory != null
    ? 'Giao dịch đã hủy vào lúc ' + toDateTimeString(transaction?.listHistory[transaction?.countHistory - 1]?.updatedDate)
    : 'Giao dịch đã hủy vào lúc ' + toDateTimeString(transaction?.updatedDate);

  const rederUnitQty = (code) => {
    if (code) {
      if (code.indexOf("users_expand") >= 0)
        return "user";
      if (code.indexOf("stores_expand") >= 0)
        return "store";
      if (code.indexOf("pages_expand") >= 0)
        return "page";
    }
    return null;
  }

  const renderTotalAmount = () => {
    let _n = <></>
    let price = transaction?.type == 'Shop plan' ? shopPlan?.cost ?? 0 : transaction?.amount ?? 0;// getPrice(transaction?.type);
    switch (transaction?.type) {
      case 'Shop feature':
        if (!features_expand.includes(serviceName) && servicePrice > 0)
          _n = <>
            <div className='calculation'> {transaction?.refNum ?? 0} * {transaction?.totalMonths} * {<Currency
                ammount={price} width='fit-content'/>}</div>
            <div className='result'>{<Currency
                ammount={(transaction?.refNum ?? 0) * (price) * (transaction?.totalMonths ?? 1)}
                width='fit-content'/>}</div>
          </>
        else _n = <>
          <div className='calculation'></div>
          <div className='result'></div>
        </>
        break;
      case 'Theme':
        _n = <>
          <div className='calculation'></div>
          <div className='result'></div>
        </>
        break;
      default:
        _n = <>
          <div className='calculation'> {transaction?.totalMonths ?? 0} * {<Currency ammount={price}
                                                                                    width='fit-content'/>}</div>
          <div className='result'>{<Currency ammount={(transaction?.totalMonths ?? 0) * (price)}
                                             width='fit-content'/>}</div>
        </>
        break;
    }
    return _n;
  }

  const renderTransactionInformation = () => {
    let unit = rederUnitQty(shopPlan?.productCode);
    return (
      <div>
        <div className='transaction-information'>
          <div className='lb-info'><b>Thông tin giao dịch</b></div>
          <div className='status'>{transaction?.status == "Đã thanh toán" ? <HrvComponents.Alert status='success' message={messageSuccess} showIcon /> :
            <HrvComponents.Alert status='default' message={messageCancel} showIcon icon={<HrvComponents.Icon type='checkCircle' theme='solid' />} />}</div>
        </div>
        <div className='pd10'>
          <div className='general-information'>
            <div className='text-left'>
              <CardTransaction name='Domain:' value={<Link to={transaction?.shop?.buyerDomain?.includes("http") ? transaction?.shop?.buyerDomain : "https://" + transaction?.shop?.buyerDomain} />}></CardTransaction>
              <CardTransaction name='Tên khách hàng:' value={`${transaction?.shop?.ownerFirstName ?? ''} ${transaction?.shop?.ownerLastName ?? ''}`}></CardTransaction>
              <CardTransaction name='Ngày phát sinh giao dịch:' value={toDateTimeString(transaction?.createdDate)}></CardTransaction>
              <CardTransaction name='Ngày hoàn tất giao dịch:' value={toDateTimeString(transaction?.completeDate)}></CardTransaction>
            </div>
            <div className='text-righ'>
              <CardTransaction name='Trạng thái giao dịch:' value={<Status text={transaction?.status} />}></CardTransaction>
              <CardTransaction name='Phương thức thanh toán:' value={transaction?.method}></CardTransaction>
              <CardTransaction name='Loại giao dịch:' value={transaction?.type}></CardTransaction>
            </div>
          </div>
          <div className='package-info'>
            <div className='package-info-left'>
              <div className='package-info-child'><CardTransaction name='Gói dịch vụ:' value={transaction?.type + " - " + serviceName}></CardTransaction></div>
              <div className='package-info-child'><CardTransaction name='Tên gói dịch vụ:' value={<b>{serviceName}</b>}></CardTransaction></div>
              {!isPackage && <div className='package-info-child'><CardTransaction name='Thời hạn:' value={<b>{features_expand.includes(serviceName) && transaction?.totalMonths < 12 ? transaction.totalDays + ' ngày': transaction.totalMonths + ' tháng' }</b>}></CardTransaction></div>}
              {unit && <div className='package-info-child'><CardTransaction name='Số lượng:' value={<b>{transaction?.refNum ?? 0} {rederUnitQty(shopPlan?.productCode)}</b>}></CardTransaction></div>}
              {servicePrice > 0 && 
                  <div className='package-info-child'>
                    <CardTransaction name='Đơn giá:' value={<b>{<CurrencyTransaction ammount={servicePrice} unit={serviceUnit} />} </b>}></CardTransaction>
                  </div>}
            </div>
            <div className='package-info-right'>
              {renderTotalAmount()}
            </div>
          </div>
          {transaction?.totalDiscount > 0 && <div className='total-money'>
            <div className='total-money-child1'></div>
            <div className='total-money-child2'>
              <div className='lb-total-money'><b>Giảm giá:</b></div>
              <div className='count-total'><b>{<Currency ammount={transaction?.totalDiscount} width='fit-content' />}</b></div>
            </div>
          </div>}
          <div className='total-money'>
            <div className='total-money-child1'></div>
            <div className='total-money-child2'>
              <div className='lb-total-money'><b>Đã thanh toán:</b></div>
              <div className='count-total'><b>{<Currency ammount={transaction?.total} width='fit-content' />}</b></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  const linkShop = "shops/" + transaction?.shopId;

  const renderWebsiteInformation = () => {
    return (
      <div className='website-info'>
        <div className='head-info'><b>Thông tin website</b></div>
        <hr />
        <CardTransactionInfo name='Tên Website:' value={<b>{transaction?.shop?.name}</b>}></CardTransactionInfo>
        <CardAddress name='Địa chỉ:' value={transaction?.shop?.address}></CardAddress>
        <CardTransactionInfo name='Điện thoại:' value={transaction?.shop?.phone}></CardTransactionInfo>
        <CardTransactionInfo name='Email:' value={<Email to={transaction?.shop?.ownerUserEmail} />}></CardTransactionInfo>
        <div className='link-show-info'> <a href={linkShop} target="_blank" ><HrvComponents.Icon type='link' theme='regular' />  Xem thông tin cửa hàng</a></div>
      </div>
    );
  }

  const renderStaffSign = () => {
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title={"Nhân viên chốt deal:"}>
          <Card >
            <div>
              {!showUpdateListUser && <div className='label-userid'>
                {transaction?.transactionUser ? <span className='lb-staffsign'>
                  {transaction?.transactionUser} </span> : <span className='lb-not-found'>Không có dữ liệu</span>}
              </div>}
              {showUpdateListUser && renderListUser()}
            </div>
            {canUpdateUser && <div className='btn-classification'>
              <div className='hrv-btn-group--item'><HrvComponents.Button onClick={UpdateUser} status='primary'>Cập nhật</HrvComponents.Button></div>
              <div className='hrv-btn-group--item'><HrvComponents.Button onClick={CancelUpdate} status='default'>Hủy</HrvComponents.Button></div>
            </div>}
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  }
  const UpdateUser = () => {
    if (showUpdateListUser) {
      UpdateTransactionUser();
    }
    setShowUpdateListUser(true);
  }
  const CancelUpdate = () => {
    setShowUpdateListUser(false);
  }
  const ChangeUser = (value) => {
    setTransaction({ ...transaction, transactionUserId: value });
    setTransactionUser(value);
  }

  const SearchUser = debounce(textSearch => {
    setSearchUser(textSearch);
  }, 300)

  const renderListUser = () => {
    return (
      <div className='input-transaction-user' ref={ref_element} style={{ position: "relative" }}>
        <HrvComponents.Selection loading={true} showSearch onSearch={SearchUser} placeholder="Chọn nhân viên" onChange={(value) => ChangeUser(value)} getPopupContainer={() => ref_element.current}>
          {listUser && listUser.map((item, index) => {
            const key = 'selectionItem-' + index;
            return <HrvComponents.Selection.Option key={key} value={item.id}>{item.name + " (" + item.email + ")"}</HrvComponents.Selection.Option>
          })}
        </HrvComponents.Selection>
      </div>
    )
  }

  const renderTag = () => {
    return (
      <div>
        <TagsInput tags={transaction?.tags}></TagsInput>
      </div>
    );
  }

  const renderNote = () => {
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title={"Ghi chú"}>
          <Card >
            <div className='grant-type'>
              <HrvComponents.Textarea value={note} onChange={(value) => setNote(value)} />
            </div>
            <div className='btn-classification d-none'>
              <div className='hrv-btn-group--item'><HrvComponents.Button onClick={UpdateNote} status='primary'>Cập nhật</HrvComponents.Button></div>
            </div>
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  }

  const renderAttribute = () => {
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title={"Thuộc tính"}>
          <Card >
            <div className='grant-type'>{
             transaction?.listAttribute && transaction?.listAttribute?.map((item) => {
                return <div>
                  <label className='lb-attribute' >{item.name}</label>
                  <HrvComponents.Input className='value-attribute' placeholder='Textbox' value={item.value} readOnly = {true}/>
                </div>
              })
            }
            </div>
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  }


  const renderInvoice = () => {
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title={"Invoice No"}>
          <Card >
            <div className='grant-type'>
              <HrvComponents.Input value={invoiceno} onChange={(value) => setInvoiceno(value)} />
            </div>
            <div className='btn-classification'>
              <div className='hrv-btn-group--item'><HrvComponents.Button onClick={UpdateInvoiceno} status='primary'>Cập nhật</HrvComponents.Button></div>
            </div>
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  }

  const renderOppoturnity = () => {
    let url;
    if (transaction?.type != null) {
      if (transaction?.isShopPlan) {
        url = process.env.REACT_APP_ADMIN_SHOPPLANS + "/" + transaction?.orderId;
      }
      else {
        if (transaction.type == "App") {
          url = process.env.REACT_APP_ADMIN_APPS + "/" + transaction?.orderId
        }
        if (transaction.type == "Theme") {
          url = process.env.REACT_APP_ADMIN_THEMES + "/" + transaction?.orderId
        }
        if (transaction.type != "App" && transaction.type != "Theme") {
          url = process.env.REACT_APP_ADMIN_SHOPPLANS + "/" + transaction?.orderId
        }
      }
    }
    return (
      <PageLayout>
        <PageLayout.AnotatedSection
          title={"OrderID tham chiếu"}>
          <Card >
            <div className='grant-type'>
              <span><a href={url} target="_blank"><DetailTransaction>{transaction?.orderId}</DetailTransaction></a></span>
            </div>
          </Card>
        </PageLayout.AnotatedSection>
      </PageLayout>
    );
  }

  const renderUpdateHistory = () => {
    return (
      <div className='render-history'>
        <div className='history'>
          <div className='title-history'>
            <div className='lb-title'><b>Lịch sử cập nhật trạng thái giao dịch</b></div>
            <hr />
          </div>
          <div className='body-history'>
            <HrvComponents.Table
              renderTableHeader={
                <tr>
                  <th className="text-center"><b>STT</b></th>
                  <th className="text-center"><b>Ngày giờ cập nhật</b></th>
                  <th className="text-center"><b>Trạng thái cập nhật</b></th>
                  <th className="text-center"><b>Người cập nhật</b></th>
                  <th className="text-center"><b>Ghi chú giao dịch</b></th>
                </tr>
              }
              renderTableBody={
                transaction?.listHistory?.map((item, index) => {
                  return <tr key={item.id + index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{toDateTimeString(item.updatedDate)}</td>
                    <td className="text-center">{<Status text={item.statusName} />}</td>
                    <td className="text-center">{item.updatedUser ? item.updatedUser : "Hệ thống"}</td>
                    <td className="text-center">{item.info != null ? FormatCategory(item.info) : item.info}</td>
                  </tr>
                })
              }
            />
          </div>
        </div>
      </div>
    );
  }

  return isLoadingData ? (
    <HrvComponents.Loading className='hrv-loading-page' size='thumb' />
  ) : (
    <div className='transaction'>
      <div className='render-body'>
        <div className='transactionInfo'>
          {renderTransactionInformation()}
        </div>
        {renderStaffSign()}
        {renderTag()}
        {renderNote()}
        {transaction?.listAttribute && renderAttribute()}
        {renderInvoice()}
        {renderOppoturnity()}
        {renderUpdateHistory()}
      </div>
      <div className='WebsiteInfo'>
        {renderWebsiteInformation()}
      </div>
    </div>
  )
}
