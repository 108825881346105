import React from "react"
import { DownloadIcon } from "./download_icon";
import * as HrvComponents from '@haravan/react-components';

import './format.css'

export const formattedCurrency = (ammount: number) =>
  new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(ammount ?? 0);

export const Currency = ({ ammount, width = '' }) => {
  const style = { width: width || '100%' }
  return <div style={style} className='format-currency'>{formattedCurrency(ammount)}</div>
}
export const CurrencyTransaction = ({ ammount, width = '', unit = '' }) => {
  const style = { width: width || '100%' }
  return <div style={style} >{formattedCurrency(ammount)}{unit}</div>
}

export const Download = ({ link, children = '', className = '' }) => {
  return <div className={`download ${className}`} onClick={() => window.location.href = link}>
    <DownloadIcon className="download-icon" />
    {children ?? <span className="download-link">{children}</span>}
  </div>
}

export const FormatAddress = (content) => {
  if (content?.includes("#")) {
    var address = content?.split("#")
    return address[0];
  }
  else {
    return content;
  }

}
export const FormatEmai = (content) => {
  if (content?.length <= 23) {
    return content
  } else {
    return content?.substring(0, 21) + "...";
  }
}
export const FormatAppName = (name) => {
  if (name?.length <= 30) {
    return name
  } else {
    return name?.substring(0, 28) + "...";
  }
}
export const FormatProductName = (name) => {
  if (name?.length <= 20) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 18) + "..."}</HrvComponents.Tooltip>;
  }
}

export const FormatPriceName = (name) => {
  if (name?.length <= 13) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 10) + "..."}</HrvComponents.Tooltip>;
  }
}

export const formatDescription = (desc) => {
  if (desc?.length <= 40) {
    return desc
  } else {
    return <HrvComponents.Tooltip title={desc}>{desc?.substring(0, 38) + "..."}</HrvComponents.Tooltip>;
  }
}

export const FormatCategory = (name) => {
  if (name?.length <= 30) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 28) + "..."}</HrvComponents.Tooltip>;
  }
}

export const FormatKey = (name) => {
  if (name?.length <= 20) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 18) + "..."}</HrvComponents.Tooltip>;
  }
}

export const FormatValue= (name) => {
  if (name?.length <= 50) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 48) + "..."}</HrvComponents.Tooltip>;
  }
}

export const PlanCode= (name) => {
  if (name?.length <= 80) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 78) + "..."}</HrvComponents.Tooltip>;
  }
}

export const FormatKeyProduct = (name) => {
  if (name?.length <= 30) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 28) + "..."}</HrvComponents.Tooltip>;
  }
}

export const FormatValueProduct= (name) => {
  if (name?.length <= 100) {
    return name
  } else {
    return <HrvComponents.Tooltip title={name}>{name?.substring(0, 98) + "..."}</HrvComponents.Tooltip>;
  }
}