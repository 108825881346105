import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toDateTimeString, IFilterType } from '../../../global/utils/functions'
import { StoreRepository, PermissionRepository } from '../../../repositories'
import { Notification } from '@haravan/react-components'
import {
  TableFilter,
  TableFilterHeader,
  Detail,
  Email,
  Link,
  Phone,
  FilterSearchModel,
  IRequestExportModel,
  T
} from '../../../components'
import { selectUser } from '../../auth/services/auth_slice'
import './index.css'
import { DetailShop } from '../../../components/link/link'

const usedBy = 'shop-list'

export const ShopList = () => {
  const [canExport, setCanExport] = useState(false)
  const user = useSelector(selectUser)

  const fetchStores = async (filter: FilterSearchModel) => {
    const response = await StoreRepository.Filter(filter)
    const list = response?.data?.data?.map(s => ({
      storeId: <DetailShop to={`/shops/${s.id}`} isDeleted={s.isDeleted} className='text-left'>{`${s.id}`}</DetailShop>,
      storeName: <div className={s.isDeleted == true  && 'is-deleted'}>{s.name}</div>,
      buyerDomain: <div className={s.isDeleted == true  && 'is-deleted'}><Link to={s.buyerDomain} /></div>,
      shopPlan: <div className={s.isDeleted == true  && 'is-deleted'}>{s.shopPlan?.name}</div>,
      ownerEmail: <div className={s.isDeleted == true  && 'is-deleted'}>{<Email to={s.ownerUserEmail} />}</div>,
      phone: <div className={s.isDeleted == true  && 'is-deleted'}>{<Phone to={s.phone} />}</div>,
      keyAccount: <div className={s.isDeleted == true  && 'is-deleted'}>{s.supportUserName ?? s.supportUser}</div>,
      expireDate: <div className={s.isDeleted == true  && 'is-deleted'}>{toDateTimeString(s.actualExpiryDate)}</div>,
      TotalStore: <div className={s.isDeleted == true  && 'is-deleted'}>{s.totalCurrentStore}</div>,
      TotaUser: <div className={s.isDeleted == true  && 'is-deleted'}>{s.totalCurrentUser}</div>,
      TotalPage: <div className={s.isDeleted == true  && 'is-deleted'}>{s.totalCurrentPage}</div>,
    }))
    const rawData = response?.data?.data
    const total = response?.data?.totalRecord
    return { list, rawData, total }
  }
  const exportData = async (data: IRequestExportModel) => {
    var response = await StoreRepository.Export(data)
    if (!response.has_error)
      Notification.success({ message: 'Danh sách cửa hàng của bạn đã bắt đầu được trích xuất. Khi hoàn tất, chúng tôi sẽ gửi một email cùng với file đến cho bạn', duration: 2 })
  }
  const processImportKA = async (file: Blob) => {
    if (file) {
      var response = await StoreRepository.ImportKA(file)
      if (response.status === 200) {
        const rs = await response.json();
        const {has_error, error_code, error_message} = rs;
        if (has_error)
          Notification.error({message: error_message, duration: 2});
        else Notification.success({ message: 'Danh sách Key Accounts của bạn đã bắt đầu được xử lý. Khi hoàn tất, chúng tôi sẽ gửi một email cùng với file đến cho bạn', duration: 2 })
        return rs;
      }
    }
    return null;
  }
  useEffect(() => {
    user &&
    PermissionRepository.GetFunctions(user.profile?.sub).then(rs => {
        setCanExport(rs?.data?.findIndex(r => ['inside.admin', 'inside.shop.export'].includes(r.code)) > -1)
      })
  }, [user])


  const headers = [
    { name: 'Id', className: 'shop-id' }, 
    { name: 'Tên website', className: 'shop-name' },
    { name: 'Website', className: 'shop-ref' },
    {
      name: 'Shop plan',
      className: 'shop-percent',
    },
    { name: 'Owner email', className: 'shop-owner-email' },
    { name: 'Điện thoại', className: 'shop-phone' },
    { name: 'Key account', className: 'shop-sign-date' },
    { name: 'Ngày hết hạn', className: 'shop-expired-date' },
    { name: 'Số lượng chi nhánh', className: 'shop-total-store' },
    { name: 'Số lượng user', className: 'shop-total-user' },
    { name: 'Số lượng fanpage', className: 'shop-total-page' },
  ] as TableFilterHeader[]

  return (
    <div className='shop-list-wrapper'>
      <TableFilter type={IFilterType.shop} usedBy={usedBy} headers={headers} canFilter={canExport} processImportKA ={canExport ? processImportKA : null} canExportData={canExport} processExportData={canExport ? exportData : null} loadData={fetchStores} placeholder="Tìm kiếm theo tên cửa hàng, domain, email người dùng, số điện thoại" />
    </div>
  )

}
