import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@haravan/react-components/dist/style.css'
import './global/css/style-bootstrap.css'
import './global/css/untils.css'
import './global/css/controls.css'

import * as serviceWorker from './serviceWorker'
import { AppProvider } from '@haravan/reactapp'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { store } from './redux_store'
import AppRouter from './router'
const baseUrl = document.getElementsByTagName('base')?.[0]?.getAttribute('href')
const container = document.getElementById('root')
const root = createRoot(container) // for automatic batching (React v18)

root.render(
  <BrowserRouter basename={baseUrl}>
    <Provider store={store}>
      <AppProvider
        options={{
          identity: process.env.REACT_APP_CLIENT_AUTHORITY,
          client_id: process.env.REACT_APP_CLIENT_ID,
          response_type: process.env.REACT_APP_RESPONSE_TYPE,
          scope: process.env.REACT_APP_SCOPE,
        }}
      >
        <AppRouter />
      </AppProvider>
    </Provider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
